import { Box } from '@mybridge/ui';
import { forwardRef, useEffect, useState } from 'react';
import { randomId } from '../utils';
import { useDispatch, useSelector } from 'react-redux';
import { setOpenAuthModal } from 'v4/store/actions/auth.actions';
export const ForceSignInWrapper = forwardRef(({ children, ...props }, ref) => {
  const dispatch = useDispatch();
  const [id, setId] = useState();
  const { loggedIn } = useSelector((s) => s.user);
  useEffect(() => {
    const id = randomId(8);
    setId(id);
  }, []);

  const redirectFn = (e) => {
    if (loggedIn) return;
    e?.stopPropagation?.();
    e?.preventDefault?.();
    dispatch(setOpenAuthModal('sign-in'));
  };
  useEffect(() => {
    if (typeof window === 'undefined') return;
    const loggedIn_ = localStorage.getItem('access');
    if (!id || loggedIn_) return;
    const parent = document.getElementById(id);
    parent?.addEventListener?.('click', redirectFn);

    return () => {
      parent.removeEventListener('click', redirectFn);
    };
  }, [id, loggedIn]);
  return (
    <Box id={id} ref={ref} {...props}>
      {children}
    </Box>
  );
});
