import { Box } from '@mybridge/ui/layout';
import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@mybridge/ui/popover';

export const InsightPopover = ({
  children,
  content,
  wrapperProps,
  triggerProps,
  ...props
}) => {
  return (
    <Popover placement="top" trigger="hover" {...(wrapperProps ?? {})}>
      <PopoverTrigger {...(triggerProps ?? {})}>{children}</PopoverTrigger>
      <PopoverContent w="auto" minW="20" bg="black" border="none">
        <PopoverArrow bg="black" />
        <PopoverBody bg="transparent" color="white" fontSize="sm" {...props}>
          {content}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
