/* eslint-disable @nx/enforce-module-boundaries */
import { Avatar, HStack, Text } from '@mybridge/ui';
import React from 'react';
import { Radio } from '@mybridge/ui';

const PostingUser = ({
  profile,
  isCompany,
  isSelected,
  setValue,
  ...props
}) => {
  const name = isCompany
    ? profile.name
    : `${profile.first_name} ${profile.last_name}`;
  return (
    <HStack
    p={2}
    _hover={{
      bg: '#E2E6EC',
      cursor:"pointer",
    }} 
      justifyContent="space-between" w="100%" backgroundColor={isSelected == profile?.id ? "#E2E6EC" : ""}
      onClick={() => setValue(profile?.id)}
      >
      <HStack gap={3}>
        <Avatar
          name={name}
          src={isCompany ? profile.image : profile.profile_pic}
        />
        <Text>{name}</Text>
      </HStack>
      <Radio
        name="posting-as"
        isChecked={isSelected == profile?.id ? true : false}
        value={profile?.id}
        onChange={(e) => setValue(e.target.value)}
      />
    </HStack>
  );
};

export default PostingUser;
