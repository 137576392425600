/* eslint-disable @nx/enforce-module-boundaries */
import {
  CommentsIcon,
  DisagreeIcon,
  HandShakeSimpleIcon,
  DisableComment,
  DisabledAgreeDisagreeIcon,
} from '@mybridge/icons';
import { Button } from '@mybridge/ui/button';
import { HStack } from '@mybridge/ui/layout';
import { useContext, useEffect } from 'react';
import { PostCommentFormContext } from 'v4/components/comment/comment-form/context';
import { PostBoxContext } from '../context';
import { PostShareMenu } from '../post-share-menu';
import { Text } from '@mybridge/ui/text';
import { useSelector } from 'react-redux';
import { Tooltip } from '@mybridge/ui';
import { ForceSignInWrapper } from 'v4/components/force-sign-in-wrapper';
import { currentProfile } from 'v4/store/actions/user-profile.actions';
import { PostCommentAvatar } from '../post-actions-avatar';

export const PostBoxFooterActions = () => {
  const {
    commentBoxVisible,
    setCommentBoxVisible,
    handleLike,
    handleDislike,
    likeLoading,
    dislikeLoading,
    post: { liked_by, dislike_by, reply_control, can_agree, can_comment, id, ad_format },
    commentsAllowed,
    handleAdClicksRouting,
    setupAdd,
    commentDisable,
    currentPostCommenter
  } = useContext(PostBoxContext);
  const { campaignDetails } = useSelector(
    (state) => state.campaignReducer
  );
  const { textInputRef } = useContext(PostCommentFormContext);
  const { userProfileInfo } = useSelector((state) => state.userProfile);
  const { loggedIn } = useSelector((state) => state.user);
  const likedByMe = liked_by&&liked_by?.find((lb) => lb?.id === userProfileInfo?.id);
  const dislikedByMe = dislike_by?.find((lb) => lb?.id === userProfileInfo?.id);

  const checkForText = () => {
    if (!can_agree && !can_comment) {
      return 'Comments, Agree & Disagree are turned off on this post.';
    }
    return !can_agree
      ? 'Agree & Disagree are turned off on this post.'
      : !can_comment
      ? 'Comments are turned off on this post.'
      : '';
  };
  const { companyPageDetail } = useSelector((state) => state.companyPage);
  const { currentProfileCommentData } = useSelector(
    (state) => state.userProfile
  );

  // useEffect(() => {
  //   if(companyPageDetail?.id)
  //     dispatch(currentProfile(companyPageDetail));
  // }, [companyPageDetail]);

  return (
    <ForceSignInWrapper>
      <HStack gap={0} px={0.5} py={1} borderTop="1px solid" borderColor="brandGray.500">
        {loggedIn && (
          <HStack flex={1} justifyContent="center">
            <PostCommentAvatar
              companyDetails={campaignDetails?.page??currentPostCommenter}
              post={true}
            />
          </HStack>
        )}

        <HStack flex={1} justifyContent="center">
          <Tooltip
            label={
              commentDisable
                ? ''
                : commentsAllowed
                ? ''
                : 'Comments disabled by author!'
            }
          >
            <Button
              w="100%"
              variant="ghost"
              leftIcon={<CommentsIcon />}
              onClick={(e) => {
                setCommentBoxVisible(!commentBoxVisible);
                handleAdClicksRouting('click', id);
                setTimeout(() => {
                  textInputRef?.current?.focus?.();
                }, 100);
              }}
              iconSpacing={[0, 0, 2]}
              isDisabled={!commentsAllowed || !can_comment}
            >
              <Text display={['none', 'none', 'block']}>Comment</Text>
            </Button>
          </Tooltip>
        </HStack>
        <HStack flex={1} justifyContent="center">
          <Button
            isLoading={likeLoading}
            onClick={handleLike}
            w="100%"
            isDisabled={!can_agree}
            variant="ghost"
            leftIcon={
              <HandShakeSimpleIcon
                height="20px"
                color={likedByMe ? '#037F29' : '#94B096'}
              />
            }
            iconSpacing={[0, 0, 2]}
          >
            <Text display={['none', 'none', 'block']}>
              {likedByMe ? 'Agreed' : 'Agree'}
            </Text>
          </Button>
        </HStack>
        <HStack flex={1} justifyContent="center">
          <Button
            isLoading={dislikeLoading}
            w="100%"
            variant="ghost"
            onClick={handleDislike}
            isDisabled={!can_agree}
            leftIcon={
              <DisagreeIcon color={dislikedByMe ? '#E41C19' : '#B38C8C'} />
            }
            iconSpacing={[0, 0, 2]}
          >
            <Text display={['none', 'none', 'block']}>
              {dislikedByMe ? 'Disagreed' : 'Disagree'}
            </Text>
          </Button>
        </HStack>
        {!ad_format && <PostShareMenu />}
      </HStack>
      {(!can_comment || !can_agree) && (
        <HStack
          px={4}
          py={1}
          borderTop="1px solid"
          borderColor="brandGray.500"
          bg="#E4E6EB"
        >
          <HStack flex={1} py={1}>
            {!can_comment && <DisableComment height="22" width="22" />}{' '}
            {!can_agree && <DisabledAgreeDisagreeIcon height="35px" />}{' '}
            {setupAdd ? (
              <Text>
                have been turned off on this post, You can still react or share
                it.
              </Text>
            ) : (
              <Text fontSize="11px">{checkForText()}</Text>
            )}
          </HStack>
        </HStack>
      )}
    </ForceSignInWrapper>
  );
};
